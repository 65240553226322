<template>
  <div class="wrapper">
    <!-- category 分类管理 -->
    <el-card class="card-1">
      <div class="header"><h2>分类管理</h2></div>
      <!-- 表单区域 -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="Category：" prop="typeName">
          <el-input v-model="ruleForm.typeName" class="category-input"></el-input>
        </el-form-item>
        <el-form-item class="category-btn">
          <el-button type="primary" size="" @click="submitForm('ruleForm')" >添加</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 分类展示区域 -->
      <div class="item-wrap" >
        <div class="item" v-for="(item,index) in categoryList" :key="index" 
          v-bind:style="{backgroundColor:getColor() }">
          <span>{{item.typeName}}</span>
          <div class="action">
            <button @click="updateCategory(item)">修改</button>.
            <button @click="deleteCategory(item.id)">删除</button>
          </div>
        </div>
      </div>
    </el-card>

    <div class="gap"></div>

    <!-- tag 标签管理 --> 
    <el-card class="card-2">
      <div class="header"><h2>标签管理</h2></div>
      <!-- 表单区域 -->
      <el-form :model="ruleFormTag" :rules="rulesTag" ref="ruleFormTag" label-width="100px" class="demo-ruleForm">
        <el-form-item label="Tag：" prop="labelName">
          <el-input v-model="ruleFormTag.labelName" class="category-input"></el-input>
        </el-form-item>
        <el-form-item label="分类：" >
          <el-select v-model="tag_of_category_value" prop="cateName" placeholder="请选择分类" class="cate_select">  <!--选择分类的下拉框-->
            <el-option
              v-for="item in category_options"
              :key="item.id"
              :label="item.typeName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="category-btn">
          <el-button type="primary" size="" @click="submitFormTag('ruleFormTag')" >添加</el-button>
          <el-button @click="reset('ruleFormTag')">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- 标签展示区域 -->
      <div class="item-wrap" >
        <div class="item" v-for="(item,index) in tagList" :key="index" 
          v-bind:style="{backgroundColor:getColor() }">
          <span>{{item.labelName}}</span>
          <div class="action">
            <button @click="updateTag(item)">修改</button>.
            <button @click="deleteTag(item.id)">删除</button>
          </div>
        </div>
      </div>
    </el-card>




    <!-- category修改dialog -->
    <div class="updateCategoryDialog" v-show="showCateDialog">
      <el-form :model="updateCategoryData"  label-width="100px" class="demo-ruleForm">
        <el-form-item label="Category：" >
          <el-input v-model="updateCategoryData.typeName" class="category-input"></el-input>
        </el-form-item>
        <el-form-item class="category-update-btn">
          <el-button type="primary" size="" @click="submitCateUpdate" >修改</el-button>
          <el-button @click="cancelCateDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="shadow" v-show="showCateDialog"> <!--category修改框的遮罩--> </div>


    <!-- tag修改dialog -->
    <div class="updateCategoryDialog" v-show="showTagDialog">
      <el-form :model="updateTagData"  label-width="100px" class="demo-ruleForm">
        <el-form-item label="Tag：" >
          <el-input v-model="updateTagData.labelName" class="category-input"></el-input>
        </el-form-item>
        <el-form-item class="category-update-btn">
          <el-button type="primary" size="" @click="submitTagUpdate" >修改</el-button>
          <el-button @click="cancelTagDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="shadow" v-show="showTagDialog"> <!--category修改框的遮罩--> </div>

  </div>
</template>


<script>
export default {
  name : 'CategoryAndTag',
  data(){
    return{
      user: {
        username: "",
        avatarUrl:"",
      },
      // category
      categoryList:[] , 
      hasLogin:false ,
      collorArr:["rgb(222,217,223)"] ,
      ruleForm: {
        id:'' ,
        typeName: ''
      },
      updateCategoryData:{} , 
      rules: {
        typeName: [
          { required: true, message: '请输入新增类名', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ]
      } , 
      showCateDialog:false ,
      // tag
      tagList:[] , 
      category_options: [],
      tag_of_category_value: '',
      ruleFormTag: {
        id:'' ,
        labelName: '' , 
        cateName:''
      },
      rulesTag: {
        labelName: [
          { required: true, message: '请输入新增标签', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ] , 
        cateName: [
          { required: true, message: '请选择分类', trigger: 'blur' }
        ] , 
      } , 
      updateTagData:{} ,
      showTagDialog:false , 
    }
  } , 
  methods:{
    //获取所有分类
    getAllCategory(){
      this.$axios.get('/getTypes' , this.ruleForm).then(res =>{
        this.categoryList = res.data.data ; 
        console.log(res.data);
        this.category_options = res.data.data ; 
        console.log(this.category_options);
        // this.total = res.data.data.total ;  
      })
    } , 
    // 获取随机颜色
    getColor(){
      let length = this.collorArr.length ; 
      let num = Math.floor(Math.random()*length);  
      return this.collorArr[num] ; 
    }  , 
    // 添加分类
    submitForm(formName) {
      if(this.hasLogin){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const _this = this ; 
            console.log(this.ruleForm);
            this.$axios.post('/category/edit' , this.ruleForm , {
              headers:{
                "Authorization":localStorage.getItem("token")
              }
            }).then(res => {
              this.$message.success("新增成功~");
              this.getAllCategory() ; 
              this.ruleForm = {} ; 
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }else{
        this.$message.error("请先登录~");
      }
    },
    // 重置按钮
    resetForm(formName) {
      this.$refs[formName].resetFields();
    } , 
    // 展示修改分类dialog框
    updateCategory(item){
      if(this.hasLogin){
        this.showCateDialog = true ; 
        this.updateCategoryData = item ; 
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 隐藏category修改框
    cancelCateDialog(){
      this.showCateDialog = !this.showCateDialog ; 
    } , 
    // 提交修改后的分类信息
    submitCateUpdate(){
      const _this = this ; 
      console.log(this.ruleForm);
      this.$axios.post('/category/edit' , this.updateCategoryData , {
        headers:{
          "Authorization":localStorage.getItem("token")
        }
      }).then(res => {
        this.$message.success("修改成功~");
        this.getAllCategory() ; 
        this.showCateDialog = !this.showCateDialog ; 
      })
    } , 
    // 删除分类
    deleteCategory(id){
      if(this.hasLogin){
        this.$confirm("确认删除" , "提示" , {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          console.log(1);
          const _this = this ; 
          _this.$axios.get("/category/delete/"+ id , {
          }).then(res => {
            this.$message.success("删除成功~");
            this.getAllCategory() ; 
          }) 
        }).catch(() => {
          return ; 
        }) ; 
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // =======================tag=============================
    testbutton(){
      console.log(this.tag_of_category_value);
    } , 
    //获取所有标签
    getAllTag(){
      this.$axios.get('/getLabels' , this.ruleForm).then(res =>{
        this.tagList = res.data.data ; 
      })
    } , 
    // 添加标签
    submitFormTag(formNameTag) {
      if(this.hasLogin){
        this.$refs[formNameTag].validate((valid) => {
          if (valid) {
            const _this = this ; 
            this.ruleFormTag.typeId = this.tag_of_category_value ; 
            this.$axios.post('/tag/edit' , this.ruleFormTag  , {
              headers:{
                "Authorization":localStorage.getItem("token")
              }
            }).then(res => {
              this.$message.success("新增成功~");
              this.getAllTag() ; 
              this.ruleFormTag = {} ; 
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }else{
        this.$message.error("请先登录~");
      }
    },
    // 重置按钮
    reset(formName){
      this.$refs[formName].resetFields();
    } ,
    // 展示修改标签dialog框
    updateTag(item){
      if(this.hasLogin){
        this.showTagDialog = true ; 
        this.updateTagData = item ; 
      }else{
        this.$message.error("请先登录~");
      }
    } , 
    // 隐藏tag修改框
    cancelTagDialog(){
      this.showTagDialog = !this.showTagDialog ; 
    } , 
    // 提交修改后的标签名
    submitTagUpdate(){
      const _this = this ; 
      console.log(this.ruleForm);
      this.$axios.post('/tag/edit' , this.updateTagData , {
        headers:{
          "Authorization":localStorage.getItem("token")
        }
      }).then(res => {
        this.$message.success("修改成功~");
        this.getAllTag() ; 
        this.showTagDialog = !this.showTagDialog ; 
      })
    } , 
    // 删除标签
    deleteTag(id){
      if(this.hasLogin){
        this.$confirm("确认删除" , "提示" , {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          const _this = this ; 
          _this.$axios.get("/tag/delete/"+ id , {
          }).then(res => {
            this.$message.success("删除成功~");
            this.getAllTag() ; 
          }) 
        }).catch(() => {
          return ; 
        }) ; 
      }else{
        this.$message.error("请先登录~");
      }
    } , 
  } ,
  created(){
    this.getAllCategory() ; 
    this.getAllTag() ; 

    // 判断是否登录
    if (this.$store.state.userInfo) {
      // this.user.username = this.$store.state.userInfo.username ; 
      // this.user.avatarUrl = this.$store.state.userInfo.avatar ; 

      this.hasLogin = true ;  
    }
  }
}
</script>


<style scoped>
.wrapper{
  display: flex;
  height: 60rem;
}
/* category */
.card-1{
  flex: 49;
}
.header{
  font-size: 1rem;
  border-bottom: 1px solid rgb(219, 218, 218);
  box-sizing: content-box;
}
.demo-ruleForm{
  margin-top: 3.5rem;
  position: relative;
}
.category-input{
  width: 20rem;
}
.category-btn{
  position: absolute;
  top: 0rem;
  left: 23rem;
}
.item-wrap{
  width: 100%;
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
}
.item{
  width: 10rem;
  height: 4rem;
  background-color: pink;
  border-radius: 1rem;
  text-align: center;
  line-height: 4rem;
  font-size: 1.2rem;
  font-weight: 600;
  margin: 1rem;
  position: relative;
  overflow: hidden;
}
.item .action{
  width: 100%;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: -10rem;
  background: rgba(1, 1, 1, .1);
}
.item:hover .action{
  left: .1rem;
}
.item .action button{
  background-color: rgba(1, 1, 1, .2);
  color: aliceblue;
  border: none;
  border-radius: .3rem;
  font-size: 1.2rem;
  padding: .5rem .7rem .5rem .7rem;
}
button:focus{outline:0;}
.item .action button:hover{
  cursor: pointer;
  background-color: rgba(1, 1, 1, .3);
}
.updateCategoryDialog{
  width: 35rem;
  height: 18rem;
  background-color: white;
  position: absolute;
  border-radius: .5rem;
  position: absolute;
  left: 50% ;
  top: 50%;
  margin-top: -14rem;
  margin-left: -22rem;
  padding: 5rem;
  z-index: 99;
}
.shadow{
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, .1);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* gap */
.gap{
  flex: 2;
}
/* tag */
.card-2{
  flex: 49;
}
.cate_select{
  width: 20rem;
}
</style>